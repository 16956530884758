import { Box } from "@mui/material";
import React from "react";
import Header from "../header/Header";

const DashboardFormLayout = ({ children }) => {
  return (
    <Box sx={{ overflow: "hidden", width: "100svw", height: "100svh" }}>
      <Header />
      {children}
    </Box>
  );
};

export default DashboardFormLayout;
