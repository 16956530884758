const initialState = {
  onBoard_product: [],
  all_products: [],
  active_id: 2,
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ACTIVE_ID_SET":
      return {
        ...state,
        active_id: action.payload,
      };
    case "PROCESSING_PRODUCT":
      return {
        ...state,
        onBoard_product: [action.payload],
      };
    case "ADD_NEW_PRODUCT":
      return {
        ...state,
        all_products: [...state.all_products, action.payload],
        onBoard_product: [],
      };
    case "EDIT_PRODUCT":
      const updatedProductsList = state.all_products.filter(
        (item) => item?.key !== action.payload.key
      );
      return {
        ...state,
        all_products: [...updatedProductsList, action.payload],
        onBoard_product: [],
      };
    case "DELETE_PRODUCT":
      const newProducts = state.all_products.filter(
        (item) => item?.key !== action.payload
      );
      return {
        ...state,
        all_products: [...newProducts],
        onBoard_product: [],
      };
    default:
      return state;
  }
};

export default productReducer;
