import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Box, Button, ButtonBase, Typography } from "@mui/material";

import AssignmentPhoto from "../../assets/Assignment.png";
import AssignmentTitle from "../../assets/AssignmentPhoto.jpeg";

import TypographyPoint from "../header/TypographyPoint";
import useFormStatus from "../../hooks/useFormStatus";
import useUserData from "../../hooks/useUserData";

const BpInstructions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data } = useUserData();
  const { formStatusRequest } = useFormStatus();

  return (
    <Box
      sx={{
        height: "88svh",
        width: "100%",
        backgroundColor: "#F7FCF4",
        padding: "24px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          border: "1px solid #3D7C65",
          borderRadius: "8px",
        }}
      >
        <Box
          sx={{
            padding: "0",
            position: "relative",
            backgroundImage: `url(${AssignmentTitle})`,
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: "#fff",
              fontSize: "28px",
              fontWeight: "600",
              textAlign: "center",
              zIndex: 999,
              padding: "1rem",
            }}
          >
            {t("BP")}
          </Typography>
        </Box>
        <Box
          sx={{
            overflowY: "auto",
            maxHeight: "74svh",
            padding: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "4px 0",
            }}
          >
            <img src={AssignmentPhoto} height={150} alt="Assignment" />
          </Box>
          <Box>
            <Typography variant="h6">{t("ExerciseInstruction")}</Typography>
            <TypographyPoint text={t("BpInstructionPointer1")} />
            <TypographyPoint text={t("BpInstructionPointer2")} />
            <TypographyPoint text={t("BpInstructionPointer3")} />
            <TypographyPoint text={t("BpInstructionPointer4")} />
          </Box>

          <Box display={"flex"} justifyContent={"center"}>
            <Button
              sx={{
                marginTop: "12px",
                bgcolor: "#3D7C65",
                padding: "6px 8px",
                fontSize: "14px",
                fontWeight: "500",
                borderRadius: "8px",
                textTransform: "none",
                width: "280px",
                "&:hover": {
                  bgcolor: "#2faa7d !important",
                },
              }}
              variant="contained"
              disabled={data?.business_presentation_feedback_state === "done"}
              onClick={() => {
                formStatusRequest({
                  status: "in_progress",
                  competency_type: "business_presentation",
                });
                navigate("/dashboard/forms/bp");
              }}
            >
              {data?.business_presentation_feedback_state === "done"
                ? t("AssessmentCompleted")
                : t("StartAssignment")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BpInstructions;
