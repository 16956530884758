import { Box } from "@mui/material";

import Header from "../header/Header";
import EmployeeFeedbackPaginationFooter from "../employee-feedback/EmployeeFeedbackPaginationFooter";

const EmployeeFeedbackLayout = ({ children }) => {
  return (
    <Box sx={{ overflow: "hidden", width: "100svw", height: "100svh" }}>
      <Header />
      {children}
      <EmployeeFeedbackPaginationFooter />
    </Box>
  );
};

export default EmployeeFeedbackLayout;
