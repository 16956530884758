import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Snackbar,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import InstructionsBack from "../assets/InstructionsBack.png";

import UBAForm from "../components/forms/UBAForm";
import UBASidebar from "../components/uba/UBASidebar";
import CustomDialog from "../components/modals/CustomDialog";

import { ArrowLeft } from "iconsax-react";
import {
  QontoConnector,
  QontoStepIcon,
} from "../components/employee-feedback/StepIconChild";
import { ArrowRight } from "iconsax-react";
import useUBAForm from "../hooks/useUBAForm";
import ProficiencyLevelDropdown from "../components/ProficiencyLevelDropdown";
import UbaPaginationFooter from "../components/uba/UBACompetencyPaginationFooter";
import UbaGlossaryContent from "../components/uba/UBAGlossaryContent";

const isPartOne = (name) => {
  return (
    name === "ONE VCB" ||
    name === "ETHICS AND COMPLIANCE" ||
    name === "SERVICE EXCELLENCE" ||
    name === "CHUNG NIỀM TIN" ||
    name === "CHUẨN MỰC VÀ  TUÂN THỦ" ||
    name === "DỊCH VỤ XUẤT SẮC"
  );
};

const UBAPage = ({ type }) => {
  const { t } = useTranslation();

  const steps = ["Questions", "Proficiency"];

  const [showGlossaryModal, setShowGlossaryModal] = useState(false);

  const {
    ubaQuestions,
    currentPage,
    totalPages,
    isLoading,
    error,
    success,
    isNextPageAssessible,
    isPartTwoAssessible,
    isFormCompleted,
    handlePageChange,
    currentPart,
    alert,
    setAlert,
    handleCurrentPartChange,
    setUbaCompetency,
    setUbaProficiency,
    submissionModalState,
    setSubmissionModalState,
  } = useUBAForm({ type });

  return (
    <Box
      sx={{
        backgroundImage: `url(${InstructionsBack})`,
        height: "78svh",
        width: "100%",
        padding: "30px",
      }}
    >
      <Box
        element="section"
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        gap={"1rem"}
        height={"100%"}
      >
        <UBASidebar
          name={ubaQuestions[currentPage]?.name}
          definition={ubaQuestions[currentPage]?.definition}
        />

        <Box
          element="main"
          display={"flex"}
          flexDirection={"column"}
          width={"75%"}
        >
          {currentPart === 0 && (
            <Box
              element="button"
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                backgroundColor: "#fff",
                padding: "0.5rem 1rem",
                borderRadius: "8px",
                cursor: "pointer",
                fontWeight: "bold",
                color: "#3D7C65",
                marginBottom: "0.5rem",
                "&:hover": {
                  backgroundColor: "#3D7C65",
                  color: "#fff",
                },
              }}
              onClick={() => setShowGlossaryModal(true)}
            >
              {t("ShowGlossary")}
            </Box>
          )}

          <CustomDialog
            open={showGlossaryModal}
            setOpen={setShowGlossaryModal}
            title={t("BelowDefProfLavel")}
            onClose={() => setShowGlossaryModal(false)}
          >
            <UbaGlossaryContent
              isModal={true}
              isPartOne={isPartOne(ubaQuestions[currentPage]?.name)}
            />
          </CustomDialog>

          {currentPart === 1 ? (
            <Box>
              <ProficiencyLevelDropdown
                t={t}
                title={t("SPL")}
                hintText={t("BasedProficiency")}
                competencyId={ubaQuestions[currentPage]?.competencyId}
                questionId={
                  ubaQuestions[currentPage]?.partTwoQuestions[0]?.question_id
                }
                handlePsuedoChange={setUbaProficiency}
                selectedValue={
                  ubaQuestions[currentPage]?.partTwoQuestions[0]
                    ?.submitted_answer_details?.score || 0
                }
                proficiencyCount={5}
                disable={isLoading}
              />

              <Box
                style={{
                  maxHeight: "calc(78svh - 300px)",
                  overflowY: "auto",
                  marginTop: "1rem",
                }}
              >
                <UbaGlossaryContent
                  isPartOne={isPartOne(ubaQuestions[currentPage]?.name)}
                />
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                gap: "1rem",
                overflowY: "auto",
                flexDirection: "column",
              }}
            >
              <UBAForm
                competencyId={ubaQuestions[currentPage]?.competencyId}
                questions={ubaQuestions[currentPage]?.partOneQuestions || []}
                handleChange={setUbaCompetency}
              />
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "1rem",
              padding: "0.5rem 1rem",
              borderRadius: "8px",
              backgroundColor: "#FFFFFFB2",
              minHeight: "90px ",
              overflowX: "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: currentPart === 1 ? "pointer" : "",
              }}
            >
              <Button
                onClick={() => handleCurrentPartChange(0)}
                sx={{
                  border: "1px solid #3D7C65",
                  borderRadius: "8px",
                  margin: "auto 0",
                  padding: "0.5rem 1rem",
                  textTransform: "none",
                  backgroundColor: currentPart === 0 ? "inherit" : "#3D7C65",
                  color: currentPart === 0 ? "#3D7C65" : "#fff",
                  "&:hover": {
                    backgroundColor: "#2faa7d",
                  },
                  mx: 4,
                }}
              >
                <ArrowLeft
                  size="24"
                  color={currentPart === 0 ? "#3D7C65" : "#fff"}
                />{" "}
                <Typography
                  sx={{
                    color: currentPart === 0 ? "#3D7C65" : "#fff",
                    fontSize: { lg: "24px" },
                    ml: 2,
                  }}
                >
                  {t("PartOne")}
                </Typography>
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                m: 1.5,
              }}
            >
              <Stepper
                activeStep={
                  isNextPageAssessible[currentPage]
                    ? 2
                    : isPartTwoAssessible[currentPage]
                    ? 1
                    : 0
                }
                alternativeLabel
                connector={<QontoConnector />}
              >
                {steps.map((label, i) => (
                  <Step key={label}>
                    <StepLabel
                      sx={{
                        "& .MuiStepLabel-label ": {
                          mt: "0px !important",
                          fontWeight: "600 !important",
                        },
                        "& .Mui-active": {
                          color: "#3D7C65 !important",
                        },
                        "& .Mui-completed ": {
                          color: "#161616 !important",
                          fontWeight: "600 !important",
                        },
                        mt: "0px",
                        minWidth: {
                          md: "100px",
                          lg: "200px",
                        },
                      }}
                      StepIconComponent={QontoStepIcon}
                    >
                      {t(label)}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: currentPart === 1 ? "" : "pointer",
              }}
            >
              <Button
                onClick={() => {
                  handleCurrentPartChange(1);
                }}
                sx={{
                  border: "1px solid #3D7C65",
                  borderRadius: "8px",
                  margin: "auto 0",
                  padding: "0.5rem 1rem",
                  textTransform: "none",
                  backgroundColor: currentPart === 1 ? "inherit" : "#3D7C65",
                  color: currentPart === 1 ? "#3D7C65" : "#fff",
                  "&:hover": {
                    backgroundColor: "#2faa7d",
                  },
                  mx: 4,
                }}
                variant="contained"
              >
                <Typography
                  sx={{
                    color: currentPart === 1 ? "#3D7C65" : "#fff",
                    fontSize: { lg: "24px" },
                    mr: 2,
                  }}
                >
                  {t("PartTwo")}
                </Typography>{" "}
                <ArrowRight
                  size="24"
                  color={currentPart === 1 ? "#3D7C65" : "#fff"}
                />
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <Snackbar
        open={alert.open}
        autoHideDuration={5000}
        onClose={() => setAlert({ open: false, message: "" })}
        sx={{ textAlign: "center" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setAlert({ open: false, message: "" })}
          severity="warning"
          sx={{ width: "100%" }}
        >
          {isPartTwoAssessible[currentPage]
            ? t("IncompleteValues1")
            : !isPartTwoAssessible[currentPage]
            ? t("IncompleteValues3")
            : t("IncompleteValues2")}
        </Alert>
      </Snackbar>

      {
        <UbaPaginationFooter
          type={type}
          prevLabel={t("Prev")}
          nextLabel={t("Next")}
          submitLabel={t("Submit")}
          currentPage={parseInt(currentPage)}
          totalPage={parseInt(totalPages)}
          isAssessmentFinished={isFormCompleted}
          onPreviousClick={() => handlePageChange("prev")}
          onNextClick={() => handlePageChange("next")}
          submissionModalState={submissionModalState}
          setSubmissionModalState={setSubmissionModalState}
        />
      }
    </Box>
  );
};

export default UBAPage;
