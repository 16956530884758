import { createTheme } from "@mui/material";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      ssm: 600,
      sm: 700,
      mmd:900,
      md: 1100,
      lg: 1400,
      xl: 1700,
      xxl: 1900,
    },
  },
});
