import { Dialog, DialogTitle, Typography, Box } from "@mui/material";

import { CloseCircle } from "iconsax-react";

const CustomDialog = ({ open, onClose, title, children, maxWidth }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth={maxWidth}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#3D7C65",
          color: "#fff",
        }}
      >
        <Typography variant="h6">{title}</Typography>
        {!!onClose && (
          <Box
            sx={{
              cursor: "pointer",
              "&:hover": {
                color: "#fff",
              },
            }}
            onClick={onClose}
          >
            <CloseCircle size="24" color="#fff" />
          </Box>
        )}
      </DialogTitle>
      <Box>{children}</Box>
    </Dialog>
  );
};

export default CustomDialog;
