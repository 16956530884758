import { useCallback, useState } from "react";
import { STORAGEKEY } from "../api";
import { URL, SET_ASSESSMENT_TIMER } from "../api/url";
import api from "../api/api";

const useSubmissionTimer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState(null);

  const PARTNER_ID = localStorage.getItem(STORAGEKEY.PARTNER_ID);

  const timerRequest = useCallback(
    async ({ startTime, endTime }) => {
      setIsLoading(true);
      setError(null);
      setSuccess(false);

      const payload = {
        start_datetime: startTime,
        end_datetime: endTime,
        partner_id: PARTNER_ID,
      };

      return;

      try {
        const response = await api.postWithToken(
          `${SET_ASSESSMENT_TIMER}`,
          payload
        );

        if (response?.data?.success) {
          setData(response?.data?.data);
          setSuccess(response?.data?.success);
        }
      } catch (error) {
        setData(null);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [PARTNER_ID]
  );

  return {
    timerRequest,
    isLoading,
    error,
    success,
    data,
  };
};

export default useSubmissionTimer;
