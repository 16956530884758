import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  FormHelperText,
  Grid,
  InputAdornment,
  OutlinedInput,
  Stack,
  IconButton,
  InputLabel,
  Box,
  Alert,
} from "@mui/material";

import * as Yup from "yup";
import { Formik } from "formik";
import { Eye, EyeSlash } from "iconsax-react";

import useAuthentication from "../../hooks/useAuthentication";
import { STORAGEKEY } from "../../api";
import { useDispatch } from "react-redux";
import { setActiveKey } from "../../store/Product/action";

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { login, isLoading, error, success, data } = useAuthentication();
  const [showPassword, setShowPassword] = useState(false);

  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (success && !!data?.access_token) {
      dispatch(setActiveKey(0));

      localStorage.setItem(STORAGEKEY.ACCESSTOKEN, data?.access_token);
      localStorage.setItem(STORAGEKEY.USER_ID, data?.user_id);
      localStorage.setItem(STORAGEKEY.PARTNER_ID, data?.partner_id);

      navigate("/dashboard");
    }
  }, [data, success, navigate, dispatch]);

  useEffect(() => {
    if (error) {
      setIsAlertVisible(true);
      const timer = setTimeout(() => {
        setIsAlertVisible(false);
      }, 3000);

      // Clear the timer when the component is unmounted or if the error changes
      return () => clearTimeout(timer);
    }
  }, [error]);

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().required("Email is required"),
        password: Yup.string().required("Password is required"),
      })}
      onSubmit={async (values) => {
        login(values.email, values.password);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel
                  htmlFor="email-login"
                  sx={{ color: "#15134B", fontWeight: "500" }}
                >
                  Username / Tên đăng nhập
                </InputLabel>
                <OutlinedInput
                  id="email-login"
                  type="email"
                  value={values.email}
                  name="email"
                  onBlur={handleBlur}
                  sx={{ borderRadius: "8px", backgroundColor: "#E3EEEA" }}
                  onChange={(e) => {
                    setFieldValue("email", e.target.value?.toLowerCase());
                  }}
                  placeholder="Enter your username"
                  fullWidth
                  error={Boolean(touched.email && errors.email)}
                />
                {touched.email && errors.email ? (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-email-login"
                  >
                    {errors.email}
                  </FormHelperText>
                ) : (
                  <Box sx={{ height: "19px" }}></Box>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <InputLabel
                  htmlFor="email-login"
                  sx={{ color: "#15134B", fontWeight: "500" }}
                >
                  Password / Mật khẩu
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  error={Boolean(touched.password && errors.password)}
                  id="-password-login"
                  type={showPassword ? "text" : "password"}
                  value={values.password}
                  name="password"
                  sx={{
                    borderRadius: "8px",
                    backgroundColor: "#E3EEEA",
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                        color="secondary"
                      >
                        {showPassword ? (
                          <Eye color={"#3D7C65"} size={20} />
                        ) : (
                          <EyeSlash color={"#3D7C65"} size={20} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  placeholder="Enter password"
                />
                {touched.password && errors.password ? (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-password-login"
                  >
                    {errors.password}
                  </FormHelperText>
                ) : (
                  <Box sx={{ height: "19px" }}></Box>
                )}
              </Stack>
            </Grid>

            {errors.submit && (
              <Grid item xs={12}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                disableElevation
                disabled={isSubmitting || isLoading}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                sx={{
                  bgcolor: "#3D7C65",
                  padding: "15px 10px",

                  fontSize: "18px",
                  fontWeight: "500",
                  borderRadius: "8px",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#2faa7d !important",
                    transform: "scale(1.02)",
                  },
                }}
              >
                Log in / Đăng nhập
              </Button>

              {isAlertVisible && (
                <Alert sx={{ marginTop: "0.5rem" }} severity="error">
                  {error}
                </Alert>
              )}
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default LoginForm;
