import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  setFeedBackCurrentPage,
  setFeedBackPageFullFill,
  setFeedBackPagePart,
} from "../../store/FeedBack/action";
import {
  Alert,
  Box,
  Button,
  Snackbar,
  Typography,
  ButtonBase,
} from "@mui/material";
import { ArrowLeft } from "iconsax-react";
import { ArrowRight } from "iconsax-react";
import i18next from "i18next";
import useEmployeeFeedback from "../../hooks/useEmployeeFeedback";
import { useLocation, useNavigate } from "react-router-dom";
import GoHomepage from "../../assets/GoHomepage.png";
import CustomDialog from "../modals/CustomDialog";
import { setActiveKey } from "../../store/Product/action";
import useFormStatus from "../../hooks/useFormStatus";

function EmployeeFeedbackPaginationFooter() {
  const { t } = useTranslation();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const lang = i18next.language;
  const [submitOpen, setSubmitOpen] = useState(false);
  const [pages, setPages] = useState([]);
  const [nextPrevent, setNextPrevent] = useState(true);
  const [alertOpen, setAlertOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const feedBackData = useSelector((state) => state.feedBackReducer);

  const {
    competencyData,
    CompetencyError,
    refetchCompetency,
    isCompetencyLoading,
    isCompetencyFetching,
  } = useEmployeeFeedback({
    offset: feedBackData?.current_page,
    partner_id: location?.state?.id,
    lang,
  });

  const { formStatusRequest } = useFormStatus();

  const handleNextEvent = (e) => {
    e.preventDefault();
    setNextPrevent(true);
    setCurrentPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    if (feedBackData?.question_id?.length > 0) {
      // Check if both parts of the current section are filled
      if (isSectionFilled()) {
        setNextPrevent(false); // Allow next prompt if both parts are filled
      } else {
        setNextPrevent(true);
      }
    }

    // Set pages in array
    if (
      feedBackData?.Feed_back_total_pages > 0 &&
      feedBackData?.Feed_back_total_pages !== pages?.length
    ) {
      setPages(
        Array.from(
          { length: feedBackData?.Feed_back_total_pages },
          (_, i) => i + 1
        )
      );
    }
  }, [feedBackData]);

  useEffect(() => {
    dispatch(setFeedBackCurrentPage(currentPage));
    dispatch(setFeedBackPagePart(0));
  }, [currentPage]);

  // Function to check if both parts of the current section are filled
  const isSectionFilled = () => {
    // Customize this logic based on your form structure
    const isCompleted = competencyData?.records[0]?.question_and_page_ids?.find(
      (item) => item?.title === "Proficiency Level"
    )?.submitted_answer_details?.submitted_answer;
    return isCompleted;
  };

  return (
    <>
      {/* Pagination buttons */}
      <Box
        sx={{
          width: "100vw",
          height: "10svh",
          backgroundColor: "#F7FCF4",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          overflow: "auto",
        }}
      >
        {/* Previous Button */}
        <Button
          onClick={() => {
            if (currentPage > 1) {
              setCurrentPage((prevPage) => prevPage - 1);
            }
          }}
          sx={{
            border: "1px solid #3D7C65",
            borderRadius: "8px",
            margin: "auto 0",
            padding: "8px",
            width: "228px",
            textTransform: "none",
            backgroundColor: currentPage === 1 ? "inherit" : "#3D7C65",
            color: currentPage === 1 ? "#3D7C65" : "#fff",
            "&:hover": {
              backgroundColor: "#2faa7d",
            },
            mx: 4,
          }}
        >
          <ArrowLeft size="24" color={currentPage === 1 ? "#3D7C65" : "#fff"} />{" "}
          <Typography
            sx={{
              color: currentPage === 1 ? "#3D7C65" : "#fff",
              fontSize: "24px",
              ml: 2,
            }}
          >
            {t("Prev")}
          </Typography>
        </Button>

        {/* Pages */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {pages.map((page, index) => (
            <Button
              key={index}
              sx={{
                border: `1px solid ${
                  currentPage === page || currentPage > page
                    ? "#3D7C65"
                    : "#D4D4D4"
                }`,
                borderRadius: "8px",
                margin: "auto 0",
                padding: "8px",
                width: "40px",
                minWidth: "40px",
                height: "40px",
                backgroundColor:
                  currentPage === page
                    ? "#F4FAEE"
                    : currentPage > page
                    ? "#3D7C65"
                    : "#ffffff",
                color:
                  currentPage === page
                    ? "#3D7C65"
                    : currentPage > page
                    ? "#ffffff"
                    : "#000000",
                "&:hover": {
                  cursor: "default",
                },
                mx: 0.5,
              }}
              variant="outlined"
              disabled={true}
            >
              <Typography
                sx={{
                  color:
                    currentPage === page
                      ? "#3D7C65"
                      : currentPage > page
                      ? "#ffffff"
                      : "#000000",
                  fontWeight: currentPage === page ? "bold" : "normal",
                  fontSize: "16px",
                }}
              >
                {page}
              </Typography>
            </Button>
          ))}
        </Box>

        {/* Next Button */}
        <Button
          onClick={(e) => {
            if (currentPage === pages.length && !nextPrevent) {
              formStatusRequest({
                status: "done",
                competency_type: "professional",
              });
              setSubmitOpen(true);
            }
            if (currentPage < pages.length + 1) {
              if (nextPrevent) {
                setAlertOpen(true);
              } else if (currentPage !== pages.length) {
                handleNextEvent(e);
              }
            }
          }}
          sx={{
            border: "1px solid #3D7C65",
            borderRadius: "8px",
            margin: "auto 0",
            padding: "8px",
            width: "228px",
            textTransform: "none",
            backgroundColor:
              currentPage === pages.length + 1 ? "inherit" : "#3D7C65",
            color: currentPage === pages.length + 1 ? "#3D7C65" : "#fff",
            "&:hover": {
              backgroundColor: "#2faa7d",
            },
            mx: 4,
          }}
          variant="contained"
        >
          <Typography
            sx={{
              color: currentPage === pages.length + 1 ? "#3D7C65" : "#fff",
              fontSize: "24px",
              mr: 2,
            }}
          >
            {currentPage === pages.length ? t("Submit") : t("Next")}
          </Typography>{" "}
          <ArrowRight
            size="24"
            color={currentPage === pages.length + 1 ? "#3D7C65" : "#fff"}
          />
        </Button>
      </Box>

      {/* Alert Snackbar */}
      <Snackbar
        open={alertOpen}
        autoHideDuration={5000}
        onClose={() => setAlertOpen(false)}
        sx={{ textAlign: "center" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setAlertOpen(false)}
          severity="warning"
          sx={{ width: "100%" }}
        >
          {feedBackData?.Feed_back_part === 1
            ? t("IncompleteValues1")
            : t("IncompleteValues2")}
        </Alert>
      </Snackbar>

      <CustomDialog
        maxWidth={"ssm"}
        open={submitOpen}
        title={t("GoHomepageTitle")}
        children={
          <Box sx={{ backgroundColor: "#F7FCF4", p: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                margin: "10px 0",
              }}
            >
              <img src={GoHomepage} width={"250px"} alt="Submitted" />
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#15134B",
                  padding: "20px 70px",
                }}
              >
                {t("GoHomepageSuccess")} <br />
                <span
                  style={{
                    fontWeight: "bold",
                    color: "#15134B",
                    fontFamily: "Inter",
                    fontSize: "20px",
                    lineHeight: "30px",
                  }}
                >
                  {state?.name || t("Employee")}
                </span>
              </Typography>
              <ButtonBase
                sx={{
                  border: "1px solid #3D7C65",
                  borderRadius: "8px",
                  margin: "25px 0",
                  padding: "8px",
                  width: "280px",
                }}
                variant="outlined"
                onClick={() => {
                  dispatch(setFeedBackPageFullFill({}));
                  dispatch(setActiveKey(0));

                  navigate("/dashboard/reviews", { replace: true });
                }}
              >
                <Typography sx={{ color: "#3D7C65" }}>
                  {t("GoHomepage")}
                </Typography>
              </ButtonBase>
            </Box>
          </Box>
        }
      />
    </>
  );
}

export default EmployeeFeedbackPaginationFooter;
