import { Box, Typography } from "@mui/material";
import React from "react";

const TypographyInfo = ({ title, value }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          pb: 0.5,
        }}
      >
        <Typography
          sx={{ color: "#15134B", fontSize: "16px", fontWeight: "600", mr: 1 }}
        >
          {title}
        </Typography>{" "}
        <Typography
          sx={{ color: "#15134B", fontSize: "16px", fontWeight: "400" }}
        >
          {value}
        </Typography>
      </Box>
    </>
  );
};

export default TypographyInfo;
