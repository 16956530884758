import { Box, Typography, Snackbar, Alert } from "@mui/material";

import InstructionsBack from "../assets/InstructionsBack.png";

import useFeedback360Degree from "../hooks/useFeedback360Degree";

import ProficiencyLevelDropdown from "../components/ProficiencyLevelDropdown";
import ExpectedScore from "../components/feedback-360-degree/ExpectedScore";
import Feedback360PaginationFooter from "../components/feedback-360-degree/Feedback360PaginationFooter";
import Feedback360DegreeSidebar from "../components/feedback-360-degree/Feedback360DegreeSidebar";

import NoDataFound from "../assets/NoDataFound.png";
import TimerComponent from "../components/Timer";

const Feedback360Degree = () => {
  const {
    t,
    error,
    alert,
    setAlert,
    competencies,
    competencyMetadata,
    currentPage,
    totalPages,
    startTime,
    handlePageChange,
    success,
    timestamp,
    selfAssessmentData,
    handleSelfAssessment,
    isAssessmentFinished,
    submissionModalState,
    setSubmissionModalState,
  } = useFeedback360Degree();

  return (
    <Box
      sx={{
        backgroundImage: `url(${InstructionsBack})`,
        height: "78svh",
        width: "100%",
        padding: "30px",
        overflowY: "hidden",
        position: "relative",
      }}
    >
      <Box
        element="section"
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        gap={"1rem"}
        sx={{
          height: "100%",
        }}
      >
        <Feedback360DegreeSidebar
          t={t}
          metadata={competencyMetadata[currentPage]}
        />

        <Box
          element="main"
          display={"flex"}
          flexDirection={"column"}
          width={"75%"}
          justifyContent={"space-between"}
        >
          {/* <Box
            sx={{
              display: {
                md: "none",
              },
              marginBottom: "1rem",
            }}
          >
            <TimerComponent timestamp={timestamp} />
          </Box> */}
          <Box
            sx={{
              marginBottom: "1rem",
              overflow: "auto",
              height: "100%",
              width: "100%",
            }}
          >
            {success && competencies[currentPage] && !error ? (
              Object.entries(competencies[currentPage])?.map(
                ([key, values], index) => (
                  <Box
                    key={`proficiency-level-${index}`}
                    sx={{
                      backgroundColor: "#FFFFFFB2",
                      padding: "1.5rem",
                      borderRadius: "6px",
                      marginBottom: "0.5rem",
                    }}
                  >
                    <Typography
                      sx={{
                        position: "relative",
                        paddingLeft: "1rem",
                        "&::before": {
                          content: '""',
                          position: "absolute",
                          left: 0,
                          top: 0,
                          bottom: 0,
                          width: "4px",
                          backgroundColor: "#3D7C65",
                          borderRadius: "3px 0 0 3px",
                        },
                        color: "#3D7C65",
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                      }}
                    >
                      {key.includes("Proficiency")
                        ? `${t("PL")} ${key.split(" ")[2]}`
                        : key}
                    </Typography>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"center"}
                      gap={"0.5rem"}
                      padding={"1rem 0"}
                    >
                      {values.map((item, i) => {
                        if (
                          item.value ===
                            "Does not demonstrate the above behaviour" ||
                          item.value === "Không thể hiện hành vi trên"
                        ) {
                          return null;
                        }

                        return (
                          <Box
                            component="span"
                            display="flex"
                            key={`proficiency-level0[${index}]-${i}`}
                          >
                            <span
                              style={{
                                fontSize: "24px",
                                marginRight: "0.45rem",
                                transform: "translateY(-0.1rem)",
                              }}
                            >
                              •
                            </span>
                            <Typography>{item.value}</Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                )
              )
            ) : (
              <Box
                sx={{
                  backgroundColor: "#F7FCF4",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "30px",
                }}
              >
                <img src={NoDataFound} height={200} alt="No Data" />
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: "600",
                    lineHeight: "31px",
                    letterSpacing: "0em",
                    textAlign: "start",
                    color: "#15134B",
                    padding: "20px 0 ",
                  }}
                >
                  {error || t("NoAssessmentFound")}
                </Typography>
              </Box>
            )}
          </Box>

          {error ? null : (
            <Box
              display={"flex"}
              alignItems={"center"}
              sx={{
                borderRadius: "6px",
                gap: "1rem",
                padding: "0.5rem 1rem",
                backgroundColor: "#FFFFFF",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: {
                  xs: "column",
                  md: "row",
                },
              }}
            >
              <ExpectedScore
                t={t}
                timestamp={timestamp}
                score={competencyMetadata[currentPage]?.expectedScore}
              />

              {/* <Box
                sx={{
                  display: {
                    xs: "none",
                    md: "flex",
                  },
                }}
              >
                <TimerComponent timestamp={timestamp} />
              </Box> */}

              <ProficiencyLevelDropdown
                t={t}
                title={t("ProficiencyLevelDropdownTitle")}
                handleChange={(e) =>
                  handleSelfAssessment(parseInt(e.target.value))
                }
                proficiencyCount={
                  success && competencies?.[currentPage]
                    ? Object.values(competencies[currentPage]).length
                    : 0
                }
                selectedValue={selfAssessmentData[currentPage] || 0}
              />
            </Box>
          )}
        </Box>
      </Box>

      <Snackbar
        open={alert.open}
        autoHideDuration={5000}
        onClose={() => setAlert({ open: false, message: "" })}
        sx={{ textAlign: "center" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setAlert({ open: false, message: "" })}
          severity="warning"
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>

      {
        <Feedback360PaginationFooter
          prevLabel={t("Prev")}
          nextLabel={t("Next")}
          submitLabel={t("Submit")}
          currentPage={parseInt(currentPage)}
          totalPage={parseInt(totalPages)}
          startTime={startTime}
          isAssessmentFinished={isAssessmentFinished}
          onPreviousClick={() => handlePageChange("prev")}
          onNextClick={() => handlePageChange("next")}
          submissionModalState={submissionModalState}
          setSubmissionModalState={setSubmissionModalState}
        />
      }
    </Box>
  );
};

export default Feedback360Degree;
