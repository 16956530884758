import i18n from "i18next";
import detector from "i18next-browser-languagedetector";

import translationEN from "../locales/en/translation.json";
import translationVI from "../locales/vi/translation.json";
import { initReactI18next } from "react-i18next";

// the translations
const resources = {
  en_US: {
    translation: translationEN,
  },
  vi_VN: {
    translation: translationVI,
  },
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "vi_VN", // set vi_VN as default language

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;