const initialState = {
  Feed_back_Loading: false,
  question_id: "",
  Feed_back_total_pages: 0,
  current_page: 1,
  Feed_back_full_fill_Id: [],
  Feed_back_part: 0,
};

const feedBackReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FEED_BACK_LOADING":
      return {
        ...state,
        Feed_back_Loading: action.payload,
      };
    case "FEED_BACK_QUESTION_ID":
      return {
        ...state,
        question_id: action.payload,
      };
    case "SET_FEED_BACK_CURRENT_PAGE":
      return {
        ...state,
        current_page: action.payload,
      };
    case "FEED_BACK_TOTAL_PAGES":
      return {
        ...state,
        Feed_back_total_pages: action.payload,
      };
    case "SET_FEED_BACK_PART":
      return {
        ...state,
        Feed_back_part: action.payload,
      };
    case "IS_FEED_BACK_QUESTION_FULL_FILL":
      const newValue =
        state?.Feed_back_full_fill_Id?.length > 0
          ? [
              state?.Feed_back_full_fill_Id.filter(
                (o) => o.competency_id !== action.payload.competency_id
              ),
              action.payload,
            ]
          : [action.payload];
      return {
        ...state,
        Feed_back_full_fill_Id: newValue,
      };

    default:
      return state;
  }
};

export default feedBackReducer;
