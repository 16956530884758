import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import TypographyPoint from "../header/TypographyPoint";
import TypographyInfo from "../header/TypographyInfo";

const Guidelines = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        height: "calc(88svh)",
        width: "100%",
        backgroundColor: "#F7FCF4",
        padding: "24px",
      }}
    >
      <Box
        sx={{
          margin: "30px 50px",
          borderRadius: "8px",
          backgroundColor: "#F7FCF4",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#3D7C65",
            color: "#fff",
            borderRadius: "8px 8px 0 0",
          }}
        >
          <Typography variant="h6" sx={{ p: 3 }}>
            {t("GuidelinesTitle")}
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: "#F7FCF4",
            p: 2,
            borderRadius: "0 0 8px 8px",
            height: "calc(100% - 100px)",
            overflow: "auto",
          }}
        >
          <TypographyPoint text={t("Guidelines1")} />
          {/* <TypographyPoint text={t("Guidelines2")} /> */}
          <TypographyPoint text={t("Guidelines3")} />
          <TypographyPoint text={t("Guidelines4")} />
          <TypographyPoint text={t("Guidelines5")} />
          <TypographyPoint text={t("Guidelines8")} />
          <TypographyPoint text={t("Guidelines9")} />
          <Box
            sx={{
              p: 1,
              ml: 4,
              border: "0.5px solid #3D7C65",
              borderRadius: "8px",
            }}
          >
            <TypographyInfo
              title={"Chị Dương Ngọc Thúy - "}
              value={t("ProjectMember1")}
            />
            <TypographyInfo
              title={"Chị Nguyễn Hồng Hà - "}
              value={t("ProjectMember2")}
            />
            <TypographyInfo
              title={"Email: "}
              value={"VCB.CFProject@vietcombank.com.vn"}
            />
          </Box>
          <TypographyPoint text={t("Guidelines10")} />
        </Box>
      </Box>
    </Box>
  );
};

export default Guidelines;
