import { Provider } from "react-redux";
import "./App.css";
import RootRouter from "./router";
import { theme } from "./theme";
import { ThemeProvider } from "@mui/material";
import { PersistGate } from "redux-persist/integration/react";
import persistStore from "redux-persist/es/persistStore";
import store from "./store/rootReducer";
import { QueryClient, QueryClientProvider } from "react-query";
import { useEffect } from "react";
import i18n from "./i18n/i18n";

function App() {
  const persistor = persistStore(store);
  const queryClient = new QueryClient();

  useEffect(() => {
    const langInLocalStorage = localStorage.getItem("selectedLanguage");
    if (!langInLocalStorage) i18n.changeLanguage("vi_VN");
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <RootRouter />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
