import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Confirmation from "../../assets/Confirmation.png";

const EmployeeFeedbackSidebar = ({ name, definition }) => {
  const { t } = useTranslation();
  const { state } = useLocation();

  return (
    <Box
      component="aside"
      sx={{
        height: "100%",
        width: "25%",
        display: "flex",
        flexDirection: "column",
        borderRadius: "8px",
      }}
    >
      <Box
        sx={{
          minHeight: "150px",
          padding: "1rem",
          backgroundImage: `url(${Confirmation})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          borderRadius: "8px 8px 0 0",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: "#fff",
            fontSize:
              "calc(11px + (24 - 16) * ((100vw - 300px) / (1600 - 300)))",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          {state?.name || t("Manager")}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            color: "#FFFFFFB2",
            fontSize: "16px",
            textAlign: "center",
          }}
        >
          {t("EmployeeReview")}
        </Typography>
      </Box>
      <Box
        sx={{
          overflowY: "auto",
          backgroundColor: "#F7FCF4",
          padding: "24px",
          flexGrow: 1,
          borderRadius: "0 0 8px 8px",
        }}
      >
        <Typography
          sx={{
            color: "#3D7C65",
            fontSize: "18px",
            fontWeight: "600",
            textAlign: "left",
            mb: 2.5,
          }}
        >
          {`${t("NameOfCompetency")}${name}`}
        </Typography>
        <Typography
          sx={{
            color: "#3D7C65",
            fontSize: "18px",
            fontWeight: "600",
            textAlign: "left",
          }}
        >
          {t("Definition")}
        </Typography>
        <Typography
          sx={{
            color: "#15134B",
            fontSize: "16px",
            fontWeight: "400",
            textAlign: "left",
            mb: 2.5,
          }}
        >
          {definition}
        </Typography>
      </Box>
    </Box>
  );
};

export default EmployeeFeedbackSidebar;
