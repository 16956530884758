import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import LoginPage from "../pages/Login";
import DashboardLayout from "../components/layouts/DashboardLayout";
import Feedback360Degree from "../pages/Feedback360Degree";

import { isAuthenticated } from "./isAuthenticated";
import DashboardFormLayout from "../components/layouts/DashboardFormLayout";
import EmployeeFeedbackLayout from "../components/layouts/EmployeeFeebackLayout";
import EmployeeFeedbackPage from "../pages/EmployeeFeedback";
import SJQ from "../pages/SJQ";
import UBAPage from "../pages/UBA";
import KLeadPage from "../pages/KLead";
import WithHeaderLayout from "../components/layouts/WithHeaderLayout";
import CompetencyInstructions from "../components/instructions/CompetencyInstructions";
import BusinessPresentation from "../pages/BusinessPresentation";

const PrivateRoute = ({ children }) => {
  const access_token = isAuthenticated();
  return access_token ? children : <Navigate to="/login" replace />;
};

const PublicRoute = ({ children }) => {
  const access_token = isAuthenticated();
  return !access_token ? children : <Navigate to="/" replace />;
};

const Layout = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/dashboard" />} />
      <Route path="/dashboard" element={<DashboardLayout />} />

      <Route
        path="/instructions"
        element={<WithHeaderLayout children={<CompetencyInstructions />} />}
      />

      <Route path="/dashboard/reviews" element={<DashboardLayout />} />
      <Route
        path="/dashboard/reviews/employee-review"
        element={<EmployeeFeedbackLayout children={<EmployeeFeedbackPage />} />}
      />
      <Route
        path="/dashboard/reviews/self-proficiency-review"
        element={<DashboardFormLayout children={<Feedback360Degree />} />}
      />

      <Route path="/dashboard/forms" element={<DashboardLayout />} />
      <Route
        path="/dashboard/forms/sjq"
        element={<DashboardFormLayout children={<SJQ />} />}
      />
      <Route
        path="dashboard/forms/uba_l1_l4"
        element={
          <DashboardFormLayout children={<UBAPage type={"uba_l1_l4"} />} />
        }
      />
      <Route
        path="/dashboard/forms/uba_l5_l14"
        element={
          <DashboardFormLayout children={<UBAPage type={"uba_l5_l14"} />} />
        }
      />
      <Route
        path="dashboard/forms/klead"
        element={<DashboardFormLayout children={<KLeadPage />} />}
      />
      <Route
        path="dashboard/forms/bp"
        element={<DashboardFormLayout children={<BusinessPresentation />} />}
      />
    </Routes>
  );
};

const RootRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/login"
          element={
            <PublicRoute>
              <LoginPage />
            </PublicRoute>
          }
        />
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <Layout />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default RootRouter;
