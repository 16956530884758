import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ProficiencyLevelDropdown from "../ProficiencyLevelDropdown";
import i18next from "i18next";
import { useLocation } from "react-router-dom";
import useEmployeeFeedback from "../../hooks/useEmployeeFeedback";
import useCompetencyProficiencyResponse from "../../hooks/useCompetencyProficiencyResponse";
import ProficiencyDescription from "../employee-feedback/ProficiencyDescription";

const ProficiencyForm = ({ feedBackData, setShow, isSectionFilled }) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const lang = i18next.language;

  const {
    competencyData,
    CompetencyError,
    refetchCompetency,
    isCompetencyLoading,
    isCompetencyFetching,
  } = useEmployeeFeedback({
    offset: feedBackData?.current_page,
    partner_id: state?.id,
    lang,
  });

  const {
    setProficiency,
    isProficiencyAnsLoading,
    proficiencyAnsError,
    proficiencyAnsSuccess,
    proficiencyAnsData,
  } = useCompetencyProficiencyResponse();

  const handleChange = (e) => {
    setProficiency(
      competencyData?.records[0]?.id,
      competencyData?.records[0]?.question_and_page_ids?.find(
        (item) => item?.title === "Proficiency Level"
      )?.question_id,
      +e.target.value,
      state?.id
    );
  };

  useEffect(() => {
    if (proficiencyAnsSuccess) {
      setShow(2);
      refetchCompetency();
    }
  }, [proficiencyAnsData]);

  return (
    <Box sx={{ overflow: "hidden" }}>
      <ProficiencyLevelDropdown
        t={t}
        title={t("ProficiencyLevelDropdownTitle")}
        hintText={t("BasedProficiency")}
        handleChange={handleChange}
        selectedValue={isSectionFilled()}
        proficiencyCount={5}
        disable={isProficiencyAnsLoading || isCompetencyLoading}
      />

      <Box
        style={{
          maxHeight: "calc(78svh - 270px)",
          overflowY: "auto",
          marginTop: "1rem",
        }}
      >
        <ProficiencyDescription />
      </Box>
    </Box>
  );
};

export default ProficiencyForm;
