import React from "react";
import { Box, ButtonBase, Typography } from "@mui/material";

const Pagination = ({ currentPage, totalPages }) => {
  let pages = [...Array(totalPages).keys()].map((i) => i + 1);
  if (totalPages > 6) {
    if (currentPage <= 4) {
      pages = [1, 2, 3, 4, 5, "...", totalPages];
    } else if (currentPage >= totalPages - 3) {
      pages = [
        1,
        "...",
        totalPages - 4,
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages,
      ];
    } else {
      pages = [
        1,
        "...",
        currentPage - 1,
        currentPage,
        currentPage + 1,
        "...",
        totalPages,
      ];
    }
  }

  return (
    <Box sx={{ display: "flex", margin: "auto 3px" }}>
      {pages.map((page, index) => (
        <ButtonBase
          key={index}
          sx={{
            border: `1px solid ${
              currentPage === page || currentPage > page ? "#3D7C65" : "#D4D4D4"
            }`,
            borderRadius: "8px",
            margin: "auto 0",
            padding: "8px",
            width: "40px",
            minWidth: "40px",
            height: "40px",
            backgroundColor:
              currentPage === page
                ? "#F4FAEE"
                : currentPage > page
                ? "#3D7C65"
                : "#ffffff",
            color:
              currentPage === page
                ? "#3D7C65"
                : currentPage > page
                ? "#ffffff"
                : "#000000",
            "&:hover": {
              cursor: "default",
            },
            mx: 0.5,
          }}
        >
          <Typography
            sx={{
              color:
                currentPage === page
                  ? "#3D7C65"
                  : currentPage > page
                  ? "#ffffff"
                  : "#000000",
              fontWeight: currentPage === page ? "bold" : "normal",
              fontSize: "16px",
            }}
          >
            {page}
          </Typography>
        </ButtonBase>
      ))}
    </Box>
  );
};

export default Pagination;
