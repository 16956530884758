import { Box, Typography } from "@mui/material";
import Confirmation from "../../assets/Confirmation.png";
import useUserData from "../../hooks/useUserData";

const Feedback360DegreeSidebar = ({ t, metadata }) => {
  const { data } = useUserData();

  return (
    <Box
      component="aside"
      sx={{
        width: "25%",
        height: "100%",
        borderRadius: "0.5rem",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "100%",
          minHeight: "150px",
          padding: "1rem",
          backgroundImage: `url(${Confirmation})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          borderRadius: "0.5rem 0.5rem 0 0",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            color: "#fff",
            fontSize: "20px",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          {data?.name || "Employee"}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            color: "#FFFFFFB2",
            fontSize: "16px",
            textAlign: "center",
          }}
        >
          {t("Assignment")}
        </Typography>
      </Box>
      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
          backgroundColor: "#F7FCF4",
          padding: "24px",
          borderRadius: "0 0 0.5rem 0.5rem",
        }}
      >
        <Typography
          sx={{
            color: "#3D7C65",
            fontSize: "18px",
            fontWeight: "600",
            textAlign: "left",
            mb: 2.5,
          }}
        >
          {`${t("NameOfCompetency")}${metadata?.name}`}
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{
              color: "#3D7C65",
              fontSize: "18px",
              fontWeight: "600",
              textAlign: "left",
            }}
          >
            {t("Definition")}
          </Typography>
          <Typography
            sx={{
              color: "#15134B",
              fontSize: "16px",
              fontWeight: "400",
              textAlign: "left",
              mb: 2.5,
            }}
          >
            {metadata?.definition}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Feedback360DegreeSidebar;
