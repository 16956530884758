import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const ProficiencyDescription = ({
  isPLWiseInstructionGlossaryHeader,
  isModal,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "flex-start",
        flexDirection: "column",
        padding: isPLWiseInstructionGlossaryHeader
          ? "1rem 0"
          : "1rem 1rem 4rem 1rem",
        overflowY: "auto",
        borderRadius: "8px",
        backgroundColor: isPLWiseInstructionGlossaryHeader
          ? "inherit"
          : "#FFFFFFB2",
      }}
    >
      {isPLWiseInstructionGlossaryHeader ? (
        <Typography variant="h6">{t("BelowDefProfLavel")}</Typography>
      ) : isModal ? null : (
        <Typography
          sx={{
            color: "#3D7C65",
            fontSize: "16px",
            fontWeight: "600",
            mb: 2,
          }}
        >
          {t("BelowDefProfLavel")}
        </Typography>
      )}
      <Typography
        sx={{
          color: "#3D7C65",
          fontSize: "16px",
          fontWeight: "600",
          mb: 2,
        }}
      >
        {t("BelowDefProfLavel2")}
      </Typography>

      <Box
        sx={{
          borderLeft: "5px solid #3D7C65",
          borderRadius: "5px",

          paddingLeft: "15px",
          marginBottom: "12px",
        }}
      >
        <Typography
          sx={{
            color: "#3D7C65",
            fontSize: "18px",
            fontWeight: "600",
            textAlign: "left",
          }}
        >
          {t("PL1Basic")}
        </Typography>
      </Box>
      <Typography
        sx={{
          display: "flex",
          color: "#15134B",
          fontSize: "16px",
          fontWeight: "400",
          textAlign: "left",
          marginLeft: "8px",
        }}
      >
        {t("PL1")}
      </Typography>
      <Box
        sx={{
          borderLeft: "5px solid #3D7C65",
          borderRadius: "5px",

          paddingLeft: "15px",
          marginBottom: "12px",
          marginTop: "22px",
        }}
      >
        <Typography
          sx={{
            color: "#3D7C65",
            fontSize: "18px",
            fontWeight: "600",
            textAlign: "left",
          }}
        >
          {t("PL2Inter")}
        </Typography>
      </Box>
      <Typography
        sx={{
          display: "flex",
          color: "#15134B",
          fontSize: "16px",
          fontWeight: "400",
          textAlign: "left",
          marginLeft: "8px",
        }}
      >
        {t("PL2")}
      </Typography>
      <Box
        sx={{
          borderLeft: "5px solid #3D7C65",
          borderRadius: "5px",

          paddingLeft: "15px",
          marginBottom: "12px",
          marginTop: "22px",
        }}
      >
        <Typography
          sx={{
            color: "#3D7C65",
            fontSize: "18px",
            fontWeight: "600",
            textAlign: "left",
          }}
        >
          {t("PL3Profound")}
        </Typography>
      </Box>
      <Typography
        sx={{
          display: "flex",
          color: "#15134B",
          fontSize: "16px",
          fontWeight: "400",
          textAlign: "left",
          marginLeft: "8px",
        }}
      >
        {t("PL3")}
      </Typography>
      <Box
        sx={{
          borderLeft: "5px solid #3D7C65",
          borderRadius: "5px",

          paddingLeft: "15px",
          marginBottom: "12px",
          marginTop: "22px",
        }}
      >
        <Typography
          sx={{
            color: "#3D7C65",
            fontSize: "18px",
            fontWeight: "600",
            textAlign: "left",
          }}
        >
          {t("PL4Expert")}
        </Typography>
      </Box>
      <Typography
        sx={{
          display: "flex",
          color: "#15134B",
          fontSize: "16px",
          fontWeight: "400",
          textAlign: "left",
          marginLeft: "8px",
        }}
      >
        {t("PL4")}
      </Typography>
      <Box
        sx={{
          borderLeft: "5px solid #3D7C65",
          borderRadius: "5px",

          paddingLeft: "15px",
          marginBottom: "12px",
          marginTop: "22px",
        }}
      >
        <Typography
          sx={{
            color: "#3D7C65",
            fontSize: "18px",
            fontWeight: "600",
            textAlign: "left",
          }}
        >
          {t("PL5Mastery")}
        </Typography>
      </Box>
      <Typography
        sx={{
          display: "flex",
          color: "#15134B",
          fontSize: "16px",
          fontWeight: "400",
          textAlign: "left",
          marginLeft: "8px",
        }}
      >
        {t("PL5")}
      </Typography>
    </Box>
  );
};

export default ProficiencyDescription;
