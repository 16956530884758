
export const addProductPrice = (payload) => ({
  type: "PROCESSING_PRODUCT",
  payload
});

export const addNewProduct = (payload) => ({
  type: "ADD_NEW_PRODUCT",
  payload
});

export const editProduct = (payload) => ({
  type: "EDIT_PRODUCT",
  payload
});

export const deleteProduct = (payload) => ({
  type: "DELETE_PRODUCT",
  payload
});

export const setActiveKey = (payload) => ({
  type: "ACTIVE_ID_SET",
  payload
});