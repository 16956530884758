import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box, ButtonBase, MenuItem, Select, Typography } from "@mui/material";

import { LogoutCurve } from "iconsax-react";
import { useTranslation } from "react-i18next";

import USA from "../../assets/USA.png";
import Logo from "../../assets/logo.png";
import Frame from "../../assets/Frame";

import CustomDialog from "../modals/CustomDialog";
import TypographyPoint from "./TypographyPoint";
import TypographyInfo from "./TypographyInfo";
import { setActiveKey } from "../../store/Product/action";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage");
    return storedLanguage || "vi_VN";
  });

  useEffect(() => {
    if (!i18n.language) {
      i18n.changeLanguage(selectedLanguage);
    }
    localStorage.setItem("selectedLanguage", selectedLanguage);
  }, [selectedLanguage, i18n]);

  const changeLanguage = (event) => {
    setSelectedLanguage(event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  const handleLogout = () => {
    localStorage.clear();
    dispatch(setActiveKey(0));
    i18n.changeLanguage("vi_VN");

    navigate("/login", { replace: true });
  };

  return (
    <Box
      sx={{
        height: "12svh",
        backgroundColor: "#F7FCF4",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        border: "0px solid #3D7C6580",
        borderWidth: "0px 0 0.1px 0",
        borderColor: "#3D7C6580",
      }}
    >
      <Box
        sx={{ marginLeft: "20px", cursor: "pointer" }}
        onClick={() => {
          dispatch(setActiveKey(0));
          navigate("/dashboard", { replace: true });
        }}
      >
        <img src={Logo} width={230} height={90} alt={"Company Logo"} />
      </Box>
      <Box sx={{ display: "flex" }}>
        <Select
          labelId="language-select-label"
          id="language-select"
          value={selectedLanguage}
          onChange={changeLanguage}
          sx={{
            border: "2px solid #E2E2EA",
            borderRadius: "8px",
            "&:focus": {
              borderColor: "#E2E2EA",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              display: "none",
            },
            margin: "30px 15px",
            padding: "5px inherit",
            height: "48px",
            width: "145px",
          }}
        >
          <MenuItem value={"vi_VN"}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img
                src={"/vietnam-flag.svg"}
                alt="Vietnam Flag"
                style={{ height: "0.85rem" }}
              />
              <Typography sx={{ marginLeft: "12px" }}>
                {t("Vietnam")}
              </Typography>
            </Box>
          </MenuItem>
          <MenuItem value={"en_US"}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img src={USA} alt="USA Flag" />
              <Typography sx={{ marginLeft: "12px" }}>
                {t("English")}
              </Typography>
            </Box>
          </MenuItem>
        </Select>

        <ButtonBase
          sx={{
            border: "2px solid #E2E2EA",
            borderRadius: "8px",
            padding: "8px",
            margin: "30px 15px",
          }}
          variant="outlined"
          onClick={() => setOpen(true)}
        >
          <Frame />
          <Typography sx={{ marginLeft: "12px", color: "#15134B" }}>
            {t("Guidelines")}
          </Typography>
        </ButtonBase>

        <Box
          sx={{
            margin: "35px 15px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={handleLogout}
        >
          <LogoutCurve size="24" color="#15134B" />
          <Box sx={{ margin: "5px", fontWeight: "600", color: "#15134B" }}>
            {t("LogOut")}
          </Box>
        </Box>
      </Box>
      <CustomDialog
        open={open}
        onClose={() => setOpen(false)}
        title={t("GuidelinesTitle")}
        children={
          <Box sx={{ p: 2 }}>
            <TypographyPoint text={t("Guidelines1")} />
            {/* <TypographyPoint text={t("Guidelines2")} /> */}
            <TypographyPoint text={t("Guidelines3")} />
            <TypographyPoint text={t("Guidelines4")} />
            <TypographyPoint text={t("Guidelines5")} />
            <TypographyPoint text={t("Guidelines8")} />
            <TypographyPoint text={t("Guidelines9")} />

            <Box
              sx={{
                p: 1,
                ml: 4,
                border: "0.5px solid #3D7C65",
                borderRadius: "8px",
              }}
            >
              <TypographyInfo
                title={"Chị Dương Ngọc Thúy - "}
                value={t("ProjectMember1")}
              />
              <TypographyInfo
                title={"Chị Nguyễn Hồng Hà - "}
                value={t("ProjectMember2")}
              />
              <TypographyInfo
                title={"Email: "}
                value={"VCB.CFProject@vietcombank.com.vn"}
              />
            </Box>
            <TypographyPoint text={t("Guidelines10")} />
          </Box>
        }
        maxWidth={"md"}
      />
    </Box>
  );
};

export default Header;
