import { Box, ListItemText, MenuItem, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Category } from "iconsax-react";
import { useTranslation } from "react-i18next";
import { setActiveKey } from "../store/Product/action";
import useUserData from "../hooks/useUserData";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeft } from "iconsax-react";

function determineRole(job_level, cn_ctc_branch_ho) {
  // Convert job_level to an integer
  const level = parseInt(job_level);

  // Check for job levels and cn_ctc_branch_ho conditions to determine the role
  if (level >= 1 && level <= 4) {
    return "JL1-4";
  } else if (level >= 5 && level <= 6) {
    if (cn_ctc_branch_ho === "VCB Trụ sở chính / HO") {
      return "JL5-6-hod";
    } else {
      return "JL5-6-branch";
    }
  } else if (level >= 7 && level <= 8) {
    return "JL7-8";
  } else if (level >= 9 && level <= 12) {
    return "JL9-12";
  } else if (level >= 13 && level <= 14) {
    return "JL13-14";
  } else if (level === 99) {
    return "JL99";
  }
}

const DashboardSidebar = ({ employListShow, competencyTestShow }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeKey = useSelector((state) => state.productReducer.active_id);

  const { data } = useUserData();

  const [mode, setMode] = useState("choose");
  const [role, setRole] = useState(null);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname === "/dashboard") {
      setMode("choose");
    } else if (pathname === "/dashboard/reviews") {
      setMode("reviews");
    } else if (pathname === "/dashboard/forms") {
      setMode("forms");
    }
  }, [pathname]);

  useEffect(() => {
    dispatch(setActiveKey(0));
  }, [mode]);

  useEffect(() => {
    if (data) {
      setRole(determineRole(data.job_level, data.cn_ctc_branch_ho));
    }
  }, [data]);

  return (
    <Box
      sx={{
        backgroundColor: "#3D7C65",
        height: "100vh",
        minHeight: "700px",
        padding: "20px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          border: "0px solid #FFFFFFCC",
          borderWidth: "0px 0 0.1px 0",
          borderColor: "#FFFFFFCC",
          padding: "24px 6px",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              color: "#FFF",
              fontSize: "22px",
              fontWeight: "700",
              margin: "10px 0",
            }}
          >
            {t("HELLO")}, {data?.name || "Manager"} 👋
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              color: "#FFFFFFCC",
              fontSize: "16px",
              fontWeight: "200",
              margin: "10px 0",
            }}
          >
            {t("WelcomeToVCB")}
          </Typography>
        </Box>
      </Box>
      <Box sx={{}}>
        <Typography
          sx={{
            color: "#FFF",
            fontSize: "16px",
            fontWeight: "500",
            margin: "20px 0",
          }}
        ></Typography>
      </Box>

      {(mode === "forms" || mode === "reviews") && (
        <Box
          sx={{
            fontSize: "16px",
            fontWeight: "500",
            margin: "20px 0",
            display: "flex",
            gap: "1rem",
            color: "#FFF",
            cursor: "pointer",
          }}
          onClick={() => navigate("/dashboard", { replace: true })}
        >
          <ArrowLeft />

          <Typography>
            {mode === "reviews"
              ? t("ProfessionalNav")
              : t("CoreAndLeadershipNav")}
          </Typography>
        </Box>
      )}

      {mode === "choose" ? (
        <>
          {role !== "JL99" &&
            data?.email !== "ducpm1.ho@vietcombank.com.vn" && (
              <MenuItem
                sx={{
                  borderRadius: "8px",
                  marginBottom: "10px",
                  color: "#fff",
                  ":hover": {
                    color: "#3D7C65",
                    backgroundColor: "#ffffffc8",
                  },
                }}
                onClick={() => {
                  navigate("/dashboard/reviews", { replace: true });
                }}
              >
                <ListItemText
                  primary={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box
                        sx={{
                          objectFit: "fill",
                          borderRadius: "5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          "&:hover": {
                            color: "#3D7C65",
                          },
                        }}
                      >
                        <Category size="24" />
                      </Box>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          marginLeft: "1em",
                          fontWeight: "600",
                        }}
                      >
                        {t("Professional")}
                      </Typography>
                    </Box>
                  }
                  sx={{ maxWidth: "90%" }}
                />
              </MenuItem>
            )}
          <MenuItem
            sx={{
              borderRadius: "8px",
              marginBottom: "10px",
              color: "#fff",
              ":hover": {
                color: "#3D7C65",
                backgroundColor: "#ffffffc8",
              },
            }}
            onClick={() => {
              navigate("/dashboard/forms", { replace: true });
            }}
          >
            <ListItemText
              primary={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{
                      objectFit: "fill",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      "&:hover": {
                        color: "#3D7C65",
                      },
                    }}
                  >
                    <Category size="24" />
                  </Box>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      marginLeft: "1em",
                      fontWeight: "600",
                    }}
                  >
                    {t("CoreAndLeadership")}
                  </Typography>
                </Box>
              }
              sx={{ maxWidth: "90%" }}
            />
          </MenuItem>
        </>
      ) : (
        <Box>
          {mode === "reviews" && (
            <>
              {employListShow && role && role !== "JL5-6-branch" && (
                <MenuItem
                  sx={{
                    backgroundColor: activeKey === 1 ? "#fff" : "#3D7C65",
                    borderRadius: "8px",
                    marginBottom: "10px",
                    ":hover": {
                      backgroundColor:
                        activeKey === 1 ? "#ffffffc8" : "#ffffff7a",
                    },
                  }}
                  key={0}
                  onClick={() => {
                    dispatch(setActiveKey(1));
                  }}
                >
                  <ListItemText
                    primary={
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box
                          sx={{
                            objectFit: "fill",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Category
                            size="24"
                            color={activeKey === 1 ? "#3D7C65" : "#fff"}
                          />
                        </Box>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            marginLeft: "1em",
                            color: activeKey === 1 ? "#3D7C65" : "#fff",
                            fontWeight: "600",
                          }}
                        >
                          {t("EmployeeReview")}
                        </Typography>
                      </Box>
                    }
                    sx={{ maxWidth: "90%" }}
                  />
                </MenuItem>
              )}
              {competencyTestShow && role !== "JL1-4" && (
                <MenuItem
                  sx={{
                    backgroundColor: activeKey === 2 ? "#fff" : "#3D7C65",
                    borderRadius: "8px",
                    marginBottom: "10px",
                    ":hover": {
                      backgroundColor:
                        activeKey === 2 ? "#ffffffc8" : "#ffffff7a",
                    },
                  }}
                  key={1}
                  onClick={() => {
                    dispatch(setActiveKey(2));
                  }}
                >
                  <ListItemText
                    primary={
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box
                          sx={{
                            objectFit: "fill",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Category
                            size="24"
                            color={activeKey === 2 ? "#3D7C65" : "#fff"}
                          />
                        </Box>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            marginLeft: "1em",
                            color: activeKey === 2 ? "#3D7C65" : "#fff",
                            fontWeight: "600",
                          }}
                        >
                          {t("Assignment")}
                        </Typography>
                      </Box>
                    }
                    sx={{ maxWidth: "90%" }}
                  />
                </MenuItem>
              )}
            </>
          )}
          {mode === "forms" && (
            <>
              {role &&
                role !== "JL1-4" &&
                role !== "JL13-14" &&
                role !== "JL99" && (
                  <MenuItem
                    sx={{
                      backgroundColor: activeKey === 3 ? "#fff" : "#3D7C65",
                      borderRadius: "8px",
                      marginBottom: "10px",
                      ":hover": {
                        backgroundColor:
                          activeKey === 3 ? "#ffffffc8" : "#ffffff7a",
                      },
                    }}
                    key={2}
                    onClick={() => {
                      dispatch(setActiveKey(3));
                    }}
                  >
                    <ListItemText
                      primary={
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Box
                            sx={{
                              objectFit: "fill",
                              borderRadius: "5px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Category
                              size="24"
                              color={activeKey === 3 ? "#3D7C65" : "#fff"}
                            />
                          </Box>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              marginLeft: "1em",
                              color: activeKey === 3 ? "#3D7C65" : "#fff",
                              fontWeight: "600",
                            }}
                          >
                            {t("SJQ")}
                          </Typography>
                        </Box>
                      }
                      sx={{ maxWidth: "90%" }}
                    />
                  </MenuItem>
                )}
              {employListShow && (
                <span>
                  {localStorage.getItem("UBA_1_4_VISIBLE") === "TRUE" &&
                    role !== "JL99" &&
                    role !== "JL5-6-branch" && (
                      <MenuItem
                        sx={{
                          backgroundColor: activeKey === 4 ? "#fff" : "#3D7C65",
                          borderRadius: "8px",
                          marginBottom: "10px",
                          ":hover": {
                            backgroundColor:
                              activeKey === 4 ? "#ffffffc8" : "#ffffff7a",
                          },
                        }}
                        key={3}
                        onClick={() => {
                          dispatch(setActiveKey(4));
                        }}
                      >
                        <ListItemText
                          primary={
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Box
                                sx={{
                                  objectFit: "fill",
                                  borderRadius: "5px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Category
                                  size="24"
                                  color={activeKey === 4 ? "#3D7C65" : "#fff"}
                                />
                              </Box>
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  marginLeft: "1em",
                                  color: activeKey === 4 ? "#3D7C65" : "#fff",
                                  fontWeight: "600",
                                }}
                              >
                                {t("UBA")}
                              </Typography>
                            </Box>
                          }
                          sx={{ maxWidth: "90%" }}
                        />
                      </MenuItem>
                    )}
                  {(localStorage.getItem("UBA_5_14_VISIBLE") === "TRUE" ||
                    role === "JL99") &&
                    role !== "JL5-6-branch" &&
                    role !== "JL1-4" && (
                      <MenuItem
                        sx={{
                          backgroundColor: activeKey === 5 ? "#fff" : "#3D7C65",
                          borderRadius: "8px",
                          marginBottom: "10px",
                          ":hover": {
                            backgroundColor:
                              activeKey === 5 ? "#ffffffc8" : "#ffffff7a",
                          },
                        }}
                        key={4}
                        onClick={() => {
                          dispatch(setActiveKey(5));
                        }}
                      >
                        <ListItemText
                          primary={
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Box
                                sx={{
                                  objectFit: "fill",
                                  borderRadius: "5px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Category
                                  size="24"
                                  color={activeKey === 5 ? "#3D7C65" : "#fff"}
                                />
                              </Box>
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  marginLeft: "1em",
                                  color: activeKey === 5 ? "#3D7C65" : "#fff",
                                  fontWeight: "600",
                                }}
                              >
                                {t("UBA")}
                              </Typography>
                            </Box>
                          }
                          sx={{ maxWidth: "90%" }}
                        />
                      </MenuItem>
                    )}
                </span>
              )}
              {role && (role === "JL13-14" || role === "JL99") && (
                <MenuItem
                  sx={{
                    backgroundColor: activeKey === 6 ? "#fff" : "#3D7C65",
                    borderRadius: "8px",
                    marginBottom: "10px",
                    ":hover": {
                      backgroundColor:
                        activeKey === 6 ? "#ffffffc8" : "#ffffff7a",
                    },
                  }}
                  key={5}
                  onClick={() => {
                    dispatch(setActiveKey(6));
                  }}
                >
                  <ListItemText
                    primary={
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box
                          sx={{
                            objectFit: "fill",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Category
                            size="24"
                            color={activeKey === 6 ? "#3D7C65" : "#fff"}
                          />
                        </Box>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            marginLeft: "1em",
                            color: activeKey === 6 ? "#3D7C65" : "#fff",
                            fontWeight: "600",
                          }}
                        >
                          {t("K-Lead")}
                        </Typography>
                      </Box>
                    }
                    sx={{ maxWidth: "90%" }}
                  />
                </MenuItem>
              )}
              {role && role === "JL5-6-branch" && (
                <MenuItem
                  sx={{
                    backgroundColor: activeKey === 7 ? "#fff" : "#3D7C65",
                    borderRadius: "8px",
                    marginBottom: "10px",
                    ":hover": {
                      backgroundColor:
                        activeKey === 7 ? "#ffffffc8" : "#ffffff7a",
                    },
                  }}
                  key={6}
                  onClick={() => {
                    dispatch(setActiveKey(7));
                  }}
                >
                  <ListItemText
                    primary={
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box
                          sx={{
                            objectFit: "fill",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Category
                            size="24"
                            color={activeKey === 7 ? "#3D7C65" : "#fff"}
                          />
                        </Box>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            marginLeft: "1em",
                            color: activeKey === 7 ? "#3D7C65" : "#fff",
                            fontWeight: "600",
                          }}
                        >
                          {t("BP")}
                        </Typography>
                      </Box>
                    }
                    sx={{ maxWidth: "90%" }}
                  />
                </MenuItem>
              )}
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default DashboardSidebar;
