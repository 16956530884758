import { GET_COMPETENCY } from "../api/url";

import api from "../api/api";

import { useQuery } from "react-query";

const useEmployeeFeedback = ({ offset, partner_id, lang }) => {
  const {
    data: competencyData,
    error: CompetencyError,
    refetch: refetchCompetency,
    isLoading: isCompetencyLoading,
    isFetching: isCompetencyFetching,
  } = useQuery(
    ["GET_COMPETENCY"],
    async () => {
      try {
        const response = await api.postWithToken(`${GET_COMPETENCY}`, {
          limit: 1,
          offset: offset - 1,
          lang,
          pl_wise: false,
          partner_id: +partner_id,
        });
        return response?.data?.data;
      } catch (error) {
        throw error;
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      retry: 0,
      staleTime: 0,
    }
  );

  return {
    competencyData,
    CompetencyError,
    refetchCompetency,
    isCompetencyLoading,
    isCompetencyFetching,
  };
};
export default useEmployeeFeedback;
