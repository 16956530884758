export const setFeedBackLoading = (payload) => ({
  type: "FEED_BACK_LOADING",
  payload,
});

export const setFeedBackTotalPages = (payload) => ({
  type: "FEED_BACK_TOTAL_PAGES",
  payload,
});

export const setFeedBackQuestionId = (payload) => ({
  type: "FEED_BACK_QUESTION_ID",
  payload,
});

export const setFeedBackCurrentPage = (payload) => ({
  type: "SET_FEED_BACK_CURRENT_PAGE",
  payload,
});

export const setFeedBackPageFullFill=(payload)=>({
  type:"IS_FEED_BACK_QUESTION_FULL_FILL",
  payload
})

export const setFeedBackPagePart=(payload)=>({
  type:"SET_FEED_BACK_PART",
  payload
})

