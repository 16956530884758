export const STORAGEKEY = {
  ACCESSTOKEN: "ACCESSTOKEN",
  USER_ID: "USER_ID",
  PARTNER_ID: "PARTNER_ID",
  ROLE: "ROLE",
  EMAIL: "EMAIL",
  SUCCESS: "SUCCESS",
  FULL_NAME: "FULLNAME",
  NEW_FEATURE_ALERT: "NEW_FEATURE_ALERT",
  REACT_APP_CAPTCHA_KEY: "6LfhfjIgAAAAAApCKJv8MwH6NDE4_xv9XP4OAhne",
};
