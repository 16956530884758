import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Logo from "../assets/logo.png";
import starLogo from "../assets/starLogo.png";
import LoginForm from "../components/forms/LoginForm";

const LoginPage = () => {
  return (
    <Box
      sx={{
        backgroundSize: "contain",
        backgroundPosition: "center",
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        "& .MuiButton-root:hover": {
          backgroundColor: "#48826c33",
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          overflow: "hidden",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: -50,
        }}
      >
        <img
          src="background.jpg"
          alt="background"
          style={{
            width: "100svw",
            height: "100svh",
            objectFit: "cover",
            filter: "brightness(0.5)",
          }}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: "#F7FCF4",
          borderRadius: "16px",
          width: {
            sm: "100%",
            md: 800,
            mmd: 900,
            lg: 1000,
            xl: 1200,
          },
          height: {
            sm: 500,
            md: 520,
            lg: 550,
            xl: 650,
          },
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                width: "100%",
                height: {
                  sm: 500,
                  md: 520,
                  lg: 550,
                  xl: 650,
                },
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#F6FBF9",
                borderRadius: "16px 0px 0px 16px",
                border: "0px solid #3D7C65",
                borderWidth: { xs: "0px", sm: "0px 0.5px 0px 0px" },
                borderColor: "#3D7C65",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={Logo} width={295} height={120} alt={"Company Logo"} />
                <Box sx={{ margin: "20px 15px 80px 15px" }}>
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "24px",
                      fontWeight: "500",
                      lineHeight: "31px",
                      letterSpacing: "0em",
                      textAlign: "center",
                      color: "#15134B",
                    }}
                  >
                    VCB Assessment Centre/<br/>Cổng đánh giá trực tuyến
                  </Typography>
                </Box>
                <img src={starLogo} width={300} height={220} alt="Star Logo" />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                width: "100%",
                height: {
                  sm: 500,
                  md: 520,
                  lg: 550,
                  xl: 650,
                },
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#F6FBF9",
                borderRadius: "0px 16px 16px 0px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  padding: "32px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: "600",
                    lineHeight: "31px",
                    letterSpacing: "0em",
                    textAlign: "start",
                    color: "#15134B",
                    padding: "15px 0 30px 0",
                  }}
                >
                  Log in / Đăng nhập
                </Typography>

                <LoginForm />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default LoginPage;
