import { Box, Grid } from "@mui/material";
import React from "react";
import Header from "../header/Header";

const WithHeaderLayout = ({ children }) => {
  return (
    <>
      <Box sx={{ boxSizing: "border-box" }}>
        <Grid container>
          <Grid item xs={12}>
            <Header />
          </Grid>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default WithHeaderLayout;
