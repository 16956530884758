import { Box, Grid } from "@mui/material";

import { useSelector } from "react-redux";

import Header from "../header/Header";
import DashboardSidebar from "../../components/DashboardSidebar";
import EmployeeReview from "../employee-feedback/EmployeeReview";
import PLWiseInstructions from "../instructions/PLWiseInstructions";
import useEmployeeData from "../../hooks/useEmployeeData";
import { useEffect, useState } from "react";
import Guidelines from "./Guidelines";
import useFeedback360Degree from "../../hooks/useFeedback360Degree";
import SjqInstructions from "../instructions/SjqInstructions";
import { setActiveKey } from "../../store/Product/action";
import { useDispatch } from "react-redux";
import KleadInstructions from "../instructions/KleadInstructions";
import BpInstructions from "../instructions/BpInstructions";

const DashboardLayout = () => {
  const dispatch = useDispatch();

  const [employListShow, setEmployList] = useState(false);
  const [competencyTestShow, setCompetencyTest] = useState(false);

  const activeKey = useSelector((state) => state.productReducer.active_id);

  const {
    isLoading: AllUsersLoading,
    error: AllUsersError,
    success: AllUsersSuccess,
    data: AllUsers,
  } = useEmployeeData();

  const { success, noRecords, fetchCompetencies } = useFeedback360Degree();

  useEffect(() => {
    if (AllUsers?.records?.length === 0) {
      setEmployList(false);
    } else {
      setEmployList(true);
    }

    // iterate all records and check their job_level and put into a set. If the set has 1-4 then return true else false
    const jobLevels = new Set();
    AllUsers?.records?.forEach((user) => {
      jobLevels.add(user.job_level);
    });

    if (
      jobLevels.has("1") ||
      jobLevels.has("2") ||
      jobLevels.has("3") ||
      jobLevels.has("4")
    ) {
      localStorage.setItem("UBA_1_4_VISIBLE", "TRUE");
    } else {
      localStorage.setItem("UBA_1_4_VISIBLE", "FALSE");
    }

    if (
      jobLevels.has("5") ||
      jobLevels.has("6") ||
      jobLevels.has("7") ||
      jobLevels.has("8") ||
      jobLevels.has("9") ||
      jobLevels.has("10") ||
      jobLevels.has("11") ||
      jobLevels.has("12") ||
      jobLevels.has("13") ||
      jobLevels.has("14")
    ) {
      localStorage.setItem("UBA_5_14_VISIBLE", "TRUE");
    } else {
      localStorage.setItem("UBA_5_14_VISIBLE", "FALSE");
    }
  }, [AllUsers]);

  useEffect(() => {
    dispatch(setActiveKey(0));
  }, [dispatch]);

  useEffect(() => {
    fetchCompetencies(1);
  }, [fetchCompetencies]);

  useEffect(() => {
    if (success) {
      if (noRecords) {
        setCompetencyTest(false);
      } else {
        setCompetencyTest(true);
      }
    }
  }, [success, noRecords]);

  return (
    <Box sx={{ height: "100svh", width: "100svw", overflow: "hidden" }}>
      <Grid container>
        <Grid item xs={3.2} lg={2.8} xl={2.6} xxl={2.4}>
          <DashboardSidebar
            employListShow={employListShow}
            competencyTestShow={competencyTestShow}
          />
        </Grid>
        <Grid item xs={8.8} lg={9.2} xl={9.4} xxl={9.6}>
          <Grid container>
            <>
              <Grid item xs={12}>
                <Header />
              </Grid>
              {(() => {
                switch (activeKey) {
                  case 0:
                    return <Guidelines />;
                  case 1:
                    return <EmployeeReview />;
                  case 2:
                    return <PLWiseInstructions />;
                  case 3:
                    return <SjqInstructions />;
                  case 4:
                    return <EmployeeReview />;
                  case 5:
                    return <EmployeeReview />;
                  case 6:
                    return <KleadInstructions />;
                  case 7:
                    return <BpInstructions />;
                  default:
                    return null;
                }
              })()}
            </>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardLayout;
