import { useState } from "react";
import axios from "axios";
import { URL, LOGIN_URL } from "../api/url";
import { useDispatch } from "react-redux";
import { addUserData } from "../store/Login/action";

const useAuthentication = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState(null);

  const login = async (email, password) => {
    setIsLoading(true);
    setError(null);
    setSuccess(false);

    const data = {
      email: email,
      password: password,
    };

    // Define headers
    const headers = {
      "Content-Type": "application/json",
    };

    try {
      const response = await axios.post(`${URL}${LOGIN_URL}`, data, {
        headers: headers,
      });
      if (response?.data?.success) {
        dispatch(addUserData(response?.data?.data));

        setData(response?.data?.data);
        setSuccess(response?.data?.success);
      }
      setIsLoading(false);
    } catch (error) {
      setData(null);
      setError(error?.response?.data?.message || error?.message);
      setIsLoading(false);
    }
  };

  return { login, isLoading, error, success, data };
};

export default useAuthentication;
