import {
  Box,
  Button,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import NoDataFound from "../../assets/NoDataFound.png";
import { useTranslation } from "react-i18next";

import useEmployeeData from "../../hooks/useEmployeeData";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { format } from "date-fns";
import { addHours } from "date-fns";
import useUserData from "../../hooks/useUserData";

function convertToVietnameseTime(value) {
  if (!value) {
    return "-";
  }

  const date = new Date(value);
  const vietnameseTime = addHours(date, 7);
  return format(vietnameseTime, "yyyy-MM-dd hh:mm a");
}

// create a utility for taking feedback state as new, in_progress and completed and yielding a t() value
const getFeedbackState = (feedbackState, t) => {
  switch (feedbackState) {
    case "new":
      return t("FeedbackNotStarted");
    case "in_progress":
      return t("FeedbackInProgress");
    case "done":
      return t("FeedbackCompleted");
    default:
      return t("FeedbackNotStarted");
  }
};

const getFeedbackColor = (feedbackState) => {
  switch (feedbackState) {
    case "done":
      return "#3D7C65";
    case "in_progress":
      return "#FFCC0F";
    default:
      return "#FFA3A3";
  }
};

const fitsInUbaL1L4 = (jobLevel) => {
  if (
    jobLevel === "1" ||
    jobLevel === "2" ||
    jobLevel === "3" ||
    jobLevel === "4"
  ) {
    console.log(jobLevel);
  }
  return (
    jobLevel === "1" || jobLevel === "2" || jobLevel === "3" || jobLevel === "4"
  );
};

const EmployeeReview = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: user } = useUserData();
  const activeKey = useSelector((state) => state.productReducer.active_id);

  const [selectedFilter, setSelectedFilter] = useState("Show All");
  const [filteredEmployees, setFilteredEmployees] = useState({ records: [] });

  const { isLoading: AllUsersLoading, data } = useEmployeeData();

  useEffect(() => {
    if (data) {
      let newData = data?.records;

      newData = newData?.filter(
        (item) =>
          !(activeKey === 4 || activeKey === 5) ||
          (activeKey === 4 && fitsInUbaL1L4(item?.job_level)) ||
          (activeKey === 5 && !fitsInUbaL1L4(item?.job_level))
      );

      newData = newData?.filter(
        (item) =>
          !(
            (item?.job_level === "5" || item?.job_level === "6") &&
            item?.cn_ctc_branch_ho !== "VCB Trụ sở chính / HO"
          )
      );

      newData = newData?.filter(
        (item) =>
          !(
            activeKey === 1 &&
            user?.email === "lhvan.ho@vietcombank.com.vn" &&
            item?.email === "ducpm1.ho@vietcombank.com.vn"
          )
      );

      newData = newData?.filter(
        (item) =>
          !(
            (activeKey === 1 && item?.job_level === "99") ||
            (activeKey === 4 && item?.job_level === "99")
          )
      );

      setFilteredEmployees({ records: newData });
    }
  }, [data, activeKey]);

  const resetData = () => {
    let newData = data?.records;
    newData = newData?.filter(
      (item) =>
        !(activeKey === 4 || activeKey === 5) ||
        (activeKey === 4 && fitsInUbaL1L4(item?.job_level)) ||
        (activeKey === 5 && !fitsInUbaL1L4(item?.job_level))
    );

    newData = newData?.filter(
      (item) =>
        !(
          (item?.job_level === "5" || item?.job_level === "6") &&
          item?.cn_ctc_branch_ho !== "VCB Trụ sở chính / HO" &&
          item?.job_level === "99"
        )
    );

    newData = newData?.filter(
      (item) =>
        !(
          activeKey === 1 &&
          user?.email === "lhvan.ho@vietcombank.com.vn" &&
          item?.email === "ducpm1.ho@vietcombank.com.vn"
        )
    );

    setFilteredEmployees({ records: newData });
  };

  const filterData = (filter) => {
    let filtered = data?.records?.filter(
      (item) =>
        (activeKey === 1 && item?.professional_feedback_state === filter) ||
        (activeKey === 4 && item?.uba_l1_l4_feedback_state === filter) ||
        (activeKey === 5 && item?.uba_l5_l14_feedback_state === filter)
    );

    filtered = filtered?.filter(
      (item) =>
        !(activeKey === 4 || activeKey === 5) ||
        (activeKey === 4 && fitsInUbaL1L4(item?.job_level)) ||
        (activeKey === 5 && !fitsInUbaL1L4(item?.job_level))
    );

    filtered = filtered?.filter(
      (item) =>
        !(
          (item?.job_level === "5" || item?.job_level === "6") &&
          item?.cn_ctc_branch_ho !== "VCB Trụ sở chính / HO"
        )
    );
    filtered = filtered?.filter(
      (item) =>
        !(
          activeKey === 1 &&
          user?.email === "lhvan.ho@vietcombank.com.vn" &&
          item?.email === "ducpm1.ho@vietcombank.com.vn"
        )
    );
    setFilteredEmployees({ records: filtered });
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#DBECE4",
      color: "#3D7C65",
      fontWeight: "600",
      borderColor: "#F6FBF9",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      backgroundColor: "#F7FCF4",
      borderColor: "#3D7C6580",
    },
    "&:nth-of-type(1)": {
      width: "25%", // Set the width of the first column
      minWidth: "250px",
    },
    "&:nth-of-type(2)": {
      width: "30%", // Set the width of the first column
      minWidth: "300px",
    },
    "&:nth-of-type(3), &:nth-of-type(4), &:nth-of-type(5), &:nth-of-type(6)": {
      width: "15%", // Set the width of the other columns
      minWidth: "180px",
    },
    "&:last-child": {
      minWidth: "120px",
      position: "sticky",
      right: 0,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#F6FBF9",
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#F6FBF9",
    },

    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const Option = ({ color, text }) => {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            width: "12px",
            height: "12px",
            backgroundColor: color,
            borderRadius: "50%",
          }}
        ></Box>
        <Typography
          sx={{
            marginLeft: "12px",
            fontSize: "16px",
            fontWeight: "600",
            color: "#15134B",
          }}
        >
          {t(text)}
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        height: "calc(100vh - 101px)",
        width: "100%",
        backgroundColor: "#F7FCF4",
        padding: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          padding: "12px 0",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "600",
              lineHeight: "31px",
              letterSpacing: "0em",
              textAlign: "start",
              color: "#15134B",
              padding: "5px 0 ",
            }}
          >
            {t("EmployeesList")}
          </Typography>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            sx={{
              "& .MuiInputBase-input": {
                padding: "10px 15px",
              },
              input: {
                borderRadius: "8px",
                border: "2px solid #E2E2EA",
              },
              margin: "0px 0px 0px 30px",
              width: "250px",
            }}
            value={selectedFilter}
            onChange={(e) => {
              setSelectedFilter(e.target.value);
              if (e.target.value === "Show All") {
                resetData();
              } else {
                filterData(e.target.value);
              }
            }}
          >
            <MenuItem value={"Show All"}>
              <Option color={"#8C8C8C"} text={t("ShowAll")} />
            </MenuItem>
            <MenuItem value={"new"}>
              <Option color={"#FFA3A3"} text={t("FeedbackNotStarted")} />
            </MenuItem>
            <MenuItem value={"in_progress"}>
              <Option color={"#FFCC0F"} text={t("FeedbackInProgress")} />
            </MenuItem>
            <MenuItem value={"done"}>
              <Option color={"#3D7C65"} text={t("FeedbackCompleted")} />
            </MenuItem>
          </Select>
        </Box>
      </Box>
      <Box
        sx={{
          padding: "15px 0",
          height: "calc(100vh - 225px)",
          overflow: "auto",
        }}
      >
        <TableContainer>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead sx={{ borderRadius: "8px" }}>
              <TableRow sx={{}}>
                <StyledTableCell sx={{ borderRadius: "8px 0 0 8px" }}>
                  {t("EMPLOYEE_NAME")} (
                  {filteredEmployees?.records?.length || 0})
                </StyledTableCell>
                <StyledTableCell>{t("EMPLOYEE_JOB_ID")}</StyledTableCell>
                <StyledTableCell>{t("EMPLOYEE_EMAIL")}</StyledTableCell>
                <StyledTableCell>{t("FEEDBACK_STATUS")}</StyledTableCell>
                <StyledTableCell>{t("TIMESTAMP")}</StyledTableCell>
                <StyledTableCell sx={{ borderRadius: "0 8px  8px 0" }}>
                  {t("ACTIONS")}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ backgroundColor: "#F7FCF4" }}>
              {AllUsersLoading || filteredEmployees?.records?.length === 0 ? (
                <StyledTableRow key={1} sx={{ width: "100%" }}>
                  <StyledTableCell colSpan={4}>
                    <Box
                      sx={{
                        backgroundColor: "#F7FCF4",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "30px",
                      }}
                    >
                      <img src={NoDataFound} height={200} alt="Not found" />
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontSize: "20px",
                          fontWeight: "600",
                          lineHeight: "31px",
                          letterSpacing: "0em",
                          textAlign: "start",
                          color: "#15134B",
                          padding: "20px 0 ",
                        }}
                      >
                        {t("NoUsersFound")}
                      </Typography>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ) : (
                filteredEmployees?.records?.map((row, i) => (
                  <StyledTableRow key={row?.id}>
                    <StyledTableCell component="th" scope="row">
                      <Typography
                        variant="subtitle1"
                        sx={{
                          color: "#3D7C65",
                          fontWeight: "600",
                        }}
                      >
                        {row?.name}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      <Typography
                        variant="subtitle1"
                        sx={{
                          color: "#3D7C65",
                          fontWeight: "600",
                        }}
                      >
                        {row?.job_id[1]}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      <Typography
                        variant="subtitle1"
                        sx={{
                          color: "#3D7C65",
                          fontWeight: "600",
                        }}
                      >
                        {row?.email}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Option
                        color={getFeedbackColor(
                          activeKey === 1
                            ? row?.professional_feedback_state
                            : activeKey === 4
                            ? row?.uba_l1_l4_feedback_state
                            : row?.uba_l5_l14_feedback_state
                        )}
                        text={getFeedbackState(
                          activeKey === 1
                            ? row?.professional_feedback_state
                            : activeKey === 4
                            ? row?.uba_l1_l4_feedback_state
                            : row?.uba_l5_l14_feedback_state,
                          t
                        )}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Typography
                        sx={{
                          marginLeft: "12px",
                          fontSize: "16px",
                          fontWeight: "600",
                          color: "#15134B",
                        }}
                      >
                        {convertToVietnameseTime(
                          (activeKey === 1 &&
                            row?.professional_feedback_submitted_on) ||
                            (activeKey === 4 &&
                              row?.uba_l1_l4_feedback_submitted_on) ||
                            (activeKey === 5 &&
                              row?.uba_l5_l14_feedback_submitted_on)
                        )}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button
                        key={i}
                        disabled={
                          (activeKey === 1 &&
                            row?.professional_feedback_state === "done") ||
                          (activeKey === 4 &&
                            row?.uba_l1_l4_feedback_state === "done") ||
                          (activeKey === 5 &&
                            row?.uba_l5_l14_feedback_state === "done")
                        }
                        sx={{
                          bgcolor: "#3D7C65",
                          padding: "6px 8px",
                          fontSize: "14px",
                          fontWeight: "500",
                          borderRadius: "4px",
                          textTransform: "none",
                          width: "120px",
                          "&:hover": {
                            bgcolor: "#2faa7d !important",
                          },
                        }}
                        variant="contained"
                        onClick={() => {
                          localStorage.setItem(
                            "INSTRUCTION_PAGE_FOR",
                            activeKey === 1
                              ? "professional"
                              : activeKey === 4
                              ? "uba_l1_l4"
                              : "uba_l5_l14"
                          );
                          navigate("/instructions", {
                            state: row,
                          });
                        }}
                      >
                        {(activeKey === 1 &&
                          row?.professional_feedback_state === "done") ||
                        (activeKey === 4 &&
                          row?.uba_l1_l4_feedback_state === "done") ||
                        (activeKey === 5 &&
                          row?.uba_l5_l14_feedback_state === "done")
                          ? t("FeedbackSubmitted")
                          : t("GiveFeedback")}
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default EmployeeReview;
