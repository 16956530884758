const initialState = {
  UBA_Loading: false,
  question_id: "",
  UBA_total_pages: 0,
  current_page: 1,
  UBA_full_fill_Id: [],
  UBA_part: 0,
};

const UBAReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UBA_LOADING":
      return {
        ...state,
        UBA_Loading: action.payload,
      };
    case "UBA_QUESTION_ID":
      return {
        ...state,
        question_id: action.payload,
      };
    case "SET_UBA_CURRENT_PAGE":
      return {
        ...state,
        current_page: action.payload,
      };
    case "UBA_TOTAL_PAGES":
      return {
        ...state,
        UBA_total_pages: action.payload,
      };
    case "SET_UBA_PART":
      return {
        ...state,
        UBA_part: action.payload,
      };
    case "IS_UBA_QUESTION_FULL_FILL":
      const newValue =
        state?.UBA_full_fill_Id?.length > 0
          ? [
              state?.UBA_full_fill_Id.filter(
                (o) => o.competency_id !== action.payload.competency_id
              ),
              action.payload,
            ]
          : [action.payload];
      return {
        ...state,
        UBA_full_fill_Id: newValue,
      };

    default:
      return state;
  }
};

export default UBAReducer;
