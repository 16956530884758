import * as React from "react";
import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

import { useTranslation } from "react-i18next";

const UBAForm = ({ competencyId, questions, handleChange }) => {
  const { t } = useTranslation();
  return (
    <>
      {questions.map((question) => (
        <Box
          key={question?.question_id}
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "0.5rem 1rem",
            borderRadius: "8px",
            backgroundColor: "#FFFFFFB2",
          }}
        >
          <Box
            element="header"
            sx={{
              borderLeft: "5px solid #3D7C65",
              borderRadius: "5px",
              paddingLeft: "15px",
              marginBottom: "12px",
              marginTop: "6px",
            }}
          >
            <Typography
              sx={{
                color: "#3D7C65",
                fontSize: "18px",
                fontWeight: "600",
                textAlign: "left",
              }}
            >
              {`${t(question.header.split(" ")[0])} ${
                question.header.split(" ")[1]
              }`}
            </Typography>
          </Box>

          <RadioGroup
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "0.5rem 1rem",
            }}
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            defaultValue={
              question.submitted_answer_details.submitted_answer_id || ""
            }
            onChange={(e) => {
              handleChange(
                competencyId,
                question?.question_id,
                parseInt(e.target.value),
                question.header
              );
            }}
          >
            {question.values.map((item) => (
              <Box key={item.answer_id} sx={{ mb: 0.75 }}>
                <FormControlLabel
                  value={item.answer_id.toString()}
                  control={
                    <Radio sx={{ p: 0.8, color: "#3D7C65" }} size="small" />
                  }
                  label={
                    <Typography
                      sx={{
                        display: "flex",
                        color:
                          question.submitted_answer_details
                            ?.submitted_answer_id === item.answer_id
                            ? "#3D7C65"
                            : "#15134B",
                        fontSize: "16px",
                        fontWeight: "400",
                        textAlign: "left",
                        marginLeft: "8px",
                      }}
                    >
                      {item.value}
                    </Typography>
                  }
                />
              </Box>
            ))}
          </RadioGroup>
        </Box>
      ))}
    </>
  );
};

export default UBAForm;
